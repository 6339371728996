import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CartesianGrid,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Tooltip,
  Bar,
  ComposedChart,
  Scatter,
  Symbols,
} from "recharts";
import styled from "styled-components";
import {
  ColorPalette,
  PageSection,
  PlusIcon,
  useDropdown,
  YukaColorPalette,
} from "yuka";

import ActiveVolumeBar from "./ActiveVolumeBar";
import AddComparisonDropdown from "./AddComparisonDropdown";
import AddIndicatorDropdown from "./AddIndicatorDropdown";
import CompanyLineGraphTooltip from "./CompanyLineGraphTooltip";
import {
  AXIS_LABEL_FONT_SIZE,
  GRAPH_TOOLTIP_STYLES,
  INDICATOR_TYPES,
  KEY_COMPANY_SERIES_TYPE,
  ONE_YEAR_KEY,
  PUBLIC_COMPARISON_SERIES_TYPE,
  SIX_MONTHS_KEY,
  THREE_MONTHS_KEY,
  TWO_YEAR_KEY,
  YTD_KEY,
  ZX_COMPANY_EXCHANGE,
  ZX_COMPARISON_SERIES_TYPE,
  ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE,
} from "./constants";
import useXAxisTicks from "./hooks/useXAxisTicks";
import LegendItem from "./LegendItem";
import {
  combinePublicAndPreIPOData,
  getActiveDot,
  makeAllIndicatorData,
  makeSuperchartData,
  pickRandomColor,
  mapGenericSecurityToId,
  mapGenericSecurityToSeriesType,
  getAbsoluteIndicatorValue,
} from "./utils";

import {
  API_ENDPOINTS,
  PRIVATE_MARKET_INDICES_FEATURE_NAME,
} from "../../api/constants";
import useFetch from "../../api/useFetch";
import useFetches from "../../api/useFetches";
import { ActionButton, OptionSelectList } from "../../hdYuka";
import { DataverseColors } from "../../hdYuka/constants";
import { ACTIONS, useDispatch } from "../../routes/StateProvider";
import applyOpacityToHex from "../../utils/applyOpacityToHex";
import { ORDER_FLOW_TIME_FRAME_MONTHLY } from "../../utils/constants";
import {
  percentFormat,
  shortMoneyFormat,
} from "../../utils/displayFormatUtils";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";
import useHasFeatureAccess from "../../utils/useHasFeatureAccess";
import ActiveDot from "../cards/ReportedMarksCard/ActiveDot";
import { useCompany } from "../hooks";
import { StyledCenteredEmptyState, StyledEmptyPill } from "../StyledComponents";

const StyledLineGraphLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const StyledGraphControlRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > :first-child {
    display: flex;
    gap: 16px;
  }
`;

const StyledLegendContainer = styled.div`
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
`;

const StyledAbsoluteEmptyPill = styled(StyledEmptyPill)`
  position: absolute;
  height: 120px;
  width: 400px;
  top: calc(50% - 60px);
  left: calc(50% - 200px);

  > :first-child {
    height: 100%;
  }
`;

const DEFAULT_DATE_RANGE = ONE_YEAR_KEY;

const getLineProps = (chartElement, activeIndex) => ({
  connectNulls: true,
  isAnimationActive: false,
  dot: ActiveDot(chartElement.color, activeIndex),
  type: "monotone",
  dataKey: chartElement.id,
  activeDot: getActiveDot(chartElement),
  strokeWidth: 2,
  stroke: chartElement.color,
});

const SHARED_BAR_PROPS = {
  yAxisId: "volumeYAxis",
  stackId: "volumeStack",
  isAnimationActive: false,
  barSize: 12,
};

const COMPARISON_MODE = "comparisonMode";
const INDICATOR_MODE = "indicatorMode";

const CompanyProfileLineGraph = ({ onClickClosedTransaction }) => {
  const dispatch = useDispatch();
  const [zxMarketIndexAccess] = useHasFeatureAccess(
    PRIVATE_MARKET_INDICES_FEATURE_NAME
  );
  const [company, companyIsLoading] = useCompany();
  const [mode, setMode] = useState(INDICATOR_MODE);
  // Store all comparisons in a single state object.
  // List of { id: "21312412", type: "PUBLIC_COMPARISON", symbol: "AAPL", data: [] }
  const [comparisons, setComparisons] = useState([]);
  const [selectedIndicators, setSelectedIndicators] = useState([
    { name: "ZIVT", color: pickRandomColor() },
  ]);
  const [selectedRange, setSelectedRange] = useState(DEFAULT_DATE_RANGE);
  // We'll leverage uuid4 collision rate to identify the hovered legend item.
  const [hoveredDate, setHoveredDate] = useState(null);
  // Will be used for mixpanel tracking.
  const [prevHoveredLegendItem, setPrevHoveredLegendItem] = useState(null);
  const [hoveredLegendItem, setHoveredLegendItem] = useState(null);

  const updateHoveredLegendItem = useCallback(
    (newHoveredLegendItem) => {
      setPrevHoveredLegendItem(hoveredLegendItem);
      setHoveredLegendItem(newHoveredLegendItem);
    },
    [hoveredLegendItem]
  );

  useEffect(() => {
    if (hoveredLegendItem && hoveredLegendItem !== prevHoveredLegendItem) {
      MixpanelEvents.hoverCompanyProfileLineChartLegendItem(
        company?.name,
        hoveredLegendItem.name
      );
    }
  }, [company?.name, hoveredLegendItem, prevHoveredLegendItem]);

  const companyOrderFlowQuery = useFetch(
    API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
    {
      company: company?.zb_id,
    },
    {
      enabled: Boolean(company?.zb_id),
    }
  );

  const monthlyOrderFlowQuery = useFetch(
    API_ENDPOINTS.COMPANY_VOLUME_TRADING_DATA(company?.zb_id),
    {
      years: 2,
      time_frame: ORDER_FLOW_TIME_FRAME_MONTHLY,
    },
    {
      enabled: Boolean(company?.zb_id),
    }
  );

  const comparisonQueryObjects = useMemo(
    () =>
      comparisons
        .map(({ type, id }) => {
          if (type === ZX_COMPARISON_SERIES_TYPE) {
            return {
              url: API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
              queryParams: { company: id },
            };
          } else if (
            type === ZX_MARKET_INDEX_SERIES_COMPARISON_TYPE &&
            zxMarketIndexAccess
          ) {
            return {
              url: API_ENDPOINTS.ZX_MARKET_INDEX_VALUES(),
              queryParams: { market_index: id },
            };
          } else if (type === PUBLIC_COMPARISON_SERIES_TYPE) {
            // Need to also add a query for the potentially existent orderflow data for
            // public companies.
            return [
              {
                url: API_ENDPOINTS.PUBLIC_SECURITY_QUOTE(),
                queryParams: { security: id },
              },
              {
                supplementary: true,
                url: API_ENDPOINTS.COMPANY_PRICE_VOLUME_ORDER_FLOW(),
                queryParams: { public_security: id },
              },
            ];
          }
        })
        .flat(),
    [zxMarketIndexAccess, comparisons]
  );
  const comparisonsQueries = useFetches(comparisonQueryObjects, {});

  // large page size so that we can get everything in one page
  const transactionRecords = useFetch(
    API_ENDPOINTS.TRANSACTION_RECORDS(),
    {
      company: company?.zb_id,
      "page[size]": 100000,
    },
    { enabled: Boolean(company?.zb_id) }
  );

  const transactionRecordsData = transactionRecords?.cleanedData?.data;

  const companyData = useMemo(() => {
    if (companyOrderFlowQuery.isSuccess) {
      return {
        id: company?.zb_id,
        name: company?.name,
        exchange: ZX_COMPANY_EXCHANGE,
        color: DataverseColors.branding400,
        data: makeSuperchartData(
          companyOrderFlowQuery.cleanedData.data,
          KEY_COMPANY_SERIES_TYPE
        )[selectedRange],
      };
    }
    return null;
  }, [
    selectedRange,
    company?.zb_id,
    company?.name,
    companyOrderFlowQuery.isSuccess,
    companyOrderFlowQuery.cleanedData,
  ]);

  const companyDataKey = useMemo(() => {
    if (mode === COMPARISON_MODE && comparisons.length > 0) {
      return "percent";
    }
    return "absolute";
  }, [comparisons.length, mode]);

  const companyIndicatorData = useMemo(() => {
    if (companyOrderFlowQuery.isSuccess && monthlyOrderFlowQuery.isSuccess) {
      return makeAllIndicatorData(
        companyOrderFlowQuery.cleanedData.data,
        monthlyOrderFlowQuery.cleanedData.data
      );
    }
    return null;
  }, [companyOrderFlowQuery, monthlyOrderFlowQuery]);
  const availableIndicators = useMemo(() => {
    // The full set of available indicators. Will be used in combination with `selectedIndicators`
    // to determine the chart data to render.
    if (!companyIndicatorData) {
      return [];
    }
    return Object.keys(companyIndicatorData)
      .map((indicatorName) => {
        if (
          !companyIndicatorData?.[indicatorName]?.[selectedRange]?.length > 0
        ) {
          return null;
        }
        return {
          id: indicatorName,
          name: indicatorName,
          ...INDICATOR_TYPES[indicatorName],
          data: companyIndicatorData[indicatorName][selectedRange],
        };
      })
      .filter((indicator) => indicator !== null);
  }, [selectedRange, companyIndicatorData]);

  const superchartComparisonData = useMemo(() => {
    if (comparisonsQueries.isAnySuccess) {
      // Since for public companies we need to query our private endpoints to cover the
      // "recently IPO'd" case we need to first pre-process the result of our comparison queries.
      // Attempt to match the public company data with the orderflow data based on location in
      // the queries output.
      const actualCleanedData = comparisonsQueries.cleanedData
        .map((comparisonData, index) => {
          if (!comparisonData && !comparisonQueryObjects[index].supplementary) {
            return comparisonData;
          }
          if (comparisonQueryObjects[index + 1]?.supplementary) {
            // combine the next query's results with this one.
            return {
              ...comparisonData,
              data: combinePublicAndPreIPOData(
                comparisonData.data,
                comparisonsQueries.cleanedData[index + 1]?.data || []
              ),
            };
          } else if (comparisonQueryObjects[index].supplementary) {
            return null;
          }
          return comparisonData;
        })
        .filter((data) => data !== null); // Remove supplementary data points.

      return actualCleanedData.map((comparisonData, index) => {
        const data = comparisonData?.data.length
          ? makeSuperchartData(comparisonData.data, comparisons[index].type)[
              selectedRange
            ]
          : [];

        return {
          ...comparisons[index],
          isLoading: !comparisonData?.data,
          data,
        };
      });
    }
    return [];
  }, [selectedRange, comparisons, comparisonQueryObjects, comparisonsQueries]);

  useEffect(() => {
    // Purge comparisons who have successfully been queried but have been found to have no data.
    // Report using a toast on the list of comparisons that had no data.
    const difference = [];
    const newComparisons = comparisons.filter((comp) => {
      const comparisonData = superchartComparisonData.find(
        (data) => data.id === comp.id
      );
      if (
        comparisonData &&
        !comparisonData.isLoading &&
        !comparisonData.data?.length
      ) {
        difference.push(comparisonData);
        return false;
      }
      // Allow comparisons that have not been loaded yet + comparisons with data through the filter.
      return true;
    });

    if (newComparisons.length !== comparisons.length) {
      // Typically because of network latency we'll see 1 of these toasts per failing company, but
      // we might as well handle the list case if there's a weird race between network responses
      // coming back and the rendering cycle.
      dispatch({
        type: ACTIONS.addToast,
        message: `No available data for ${difference
          .map((comparison) => comparison.name)
          .join(", ")}.`,
      });
      setComparisons(newComparisons);
    }
  }, [comparisons, superchartComparisonData, dispatch]);

  const xAxisTicks = useXAxisTicks(companyData, superchartComparisonData);

  const superchartIndicatorData = useMemo(() => {
    // The actual chart data to render for the indicators.
    if (!companyIndicatorData) return [];

    return availableIndicators
      .filter((indicator) =>
        Boolean(selectedIndicators.find((i) => i.name === indicator.id))
      )
      .map((indicator) => ({
        ...indicator,
        color: selectedIndicators.find((i) => i.name === indicator.id).color,
      }));
  }, [availableIndicators, companyIndicatorData, selectedIndicators]);

  const onSelectComparison = useCallback(
    (security) => {
      setMode(COMPARISON_MODE);
      if (
        comparisons.find((comp) => comp.id === mapGenericSecurityToId(security))
      ) {
        dispatch({
          type: ACTIONS.addToast,
          message: `You've already added ${security.symbol} to the chart.`,
        });
        return;
      }
      setComparisons([
        ...comparisons,
        {
          id: mapGenericSecurityToId(security),
          type: mapGenericSecurityToSeriesType(security),
          name: security.symbol,
          color: pickRandomColor(),
          exchange: security.exchange,
          enabled: true,
        },
      ]);
      MixpanelEvents.addComparisonToCompanyProfileLineChart(
        company?.name,
        security.symbol,
        security.exchange
      );
    },
    [dispatch, comparisons, company?.name]
  );

  const onRemoveComparison = useCallback(
    (comparisonId) => {
      const removedComparison = comparisons.find(
        (comp) => comp.id === comparisonId
      );
      MixpanelEvents.removeComparisonFromCompanyProfileLineChart(
        company?.name,
        removedComparison.name,
        removedComparison.exchange
      );
      const newComparisons = comparisons.filter(
        (comp) => comp.id !== comparisonId
      );
      if (newComparisons.length === 0) {
        setMode(INDICATOR_MODE);
      }
      setComparisons(newComparisons);
      updateHoveredLegendItem(null);
    },
    [updateHoveredLegendItem, company?.name, comparisons]
  );

  const onSelectIndicator = useCallback(
    (indicatorName) => {
      if (companyData) {
        setMode(INDICATOR_MODE);
        setSelectedIndicators([
          ...new Set([
            ...selectedIndicators,
            {
              name: indicatorName,
              color: pickRandomColor(),
            },
          ]),
        ]);
      }
      MixpanelEvents.addIndicatorToCompanyProfileLineChart(
        company?.name,
        INDICATOR_TYPES[indicatorName].name
      );
    },
    [company?.name, companyData, selectedIndicators]
  );

  const onRemoveIndicator = useCallback(
    (source, indicatorName) => {
      const newIndicators = selectedIndicators.filter(
        (indicator) => indicator.name !== indicatorName
      );
      if (newIndicators.length === 0) {
        setMode(COMPARISON_MODE);
      }
      setSelectedIndicators(newIndicators);
      updateHoveredLegendItem(null);
      MixpanelEvents.removeIndicatorFromCompanyProfileLineChart(
        source,
        company?.name,
        INDICATOR_TYPES[indicatorName].name
      );
    },
    [company?.name, updateHoveredLegendItem, selectedIndicators]
  );

  const onRangeOptionChange = useCallback(
    (option) => {
      MixpanelEvents.changeCompanyProfileLineChartTimeFrame(
        company?.name,
        selectedRange,
        option
      );
      setSelectedRange(option);
    },
    [company?.name, selectedRange]
  );

  const [addComparisonDropdown, addComparisonRef, toggleAddComparisonDropdown] =
    useDropdown(({ toggleIsOpen }) => (
      <AddComparisonDropdown
        onSelectComparison={(security) => {
          onSelectComparison(security);
          toggleIsOpen();
        }}
      />
    ));

  const chartData = useMemo(() => {
    if (!companyData) {
      return null;
    }
    // Get full list of dates from the `time` field of the comparisons and companyData. We
    // get the list based on the current mode, so we don't use comparison dates when we're
    // in indicator mode and vice versa.
    const filteredCompanyData = companyData.data.filter((datum) => {
      if (mode === COMPARISON_MODE) {
        return datum.percent !== null;
      }
      return datum.absolute !== null;
    });

    const allDates = [
      ...new Set(
        [
          ...filteredCompanyData.map((data) => data.time),
          ...(mode === COMPARISON_MODE
            ? superchartComparisonData
                .map((data) =>
                  data.data
                    .filter((datum) => Object.keys(datum).length > 1)
                    .map((d) => d.time)
                )
                .flat()
            : []),
          ...(mode === INDICATOR_MODE || superchartComparisonData === 0
            ? superchartIndicatorData
                .map((data) => data.data.map((d) => d.time))
                .flat()
            : []),
        ].sort()
      ),
    ];

    // Construct these 3 specialized data structures to provide O(1) lookups in the allDates.map
    // step next.
    const companyDataPointsByDate = _.keyBy(companyData.data, "time");
    const comparisonDataByDate =
      mode === COMPARISON_MODE
        ? superchartComparisonData.map((comparison) => ({
            id: comparison.id,
            data: _.keyBy(comparison.data, "time"),
          }))
        : [];
    const indicatorDataByDate =
      mode === INDICATOR_MODE || superchartComparisonData.length === 0
        ? superchartIndicatorData.map((indicator) => ({
            id: indicator.id,
            name: indicator.name,
            getIndicatorValueObject:
              indicator.getIndicatorValueObject || getAbsoluteIndicatorValue,
            data: _.keyBy(indicator.data, "time"),
          }))
        : [];

    const transactionRecordDataByDate = _.groupBy(
      transactionRecordsData,
      (transactionRecord) => {
        const transactionDate =
          transactionRecord.closing_date || transactionRecord.noticed_date;
        return DateTime.fromISO(transactionDate)
          .set({ weekday: 5 })
          .toISODate();
      }
    );

    // Create a new list of data points, where each point contains the date, the percent and
    // absolute values from the companyData, the percent or absolute values from the indicator
    // data, and the percent values from the comparisonData, to be fed into the recharts LineGraph.
    return allDates.map((date) => {
      const companyDataPoint = companyDataPointsByDate[date];
      const comparisonDataPoints = comparisonDataByDate.map(({ id, data }) => {
        const comparisonDataPoint = data[date];
        return comparisonDataPoint ? { [id]: comparisonDataPoint.percent } : {};
      });
      const indicatorDataPoints = indicatorDataByDate.map(
        ({ id, data, getIndicatorValueObject }) => {
          const indicatorDataPoint = data[date];

          return indicatorDataPoint
            ? getIndicatorValueObject(id, indicatorDataPoint)
            : {};
        }
      );
      const transactionRecordDataPoint = transactionRecordDataByDate[date]
        ? {
            transactionRecords: transactionRecordDataByDate[date],
            transactionRecordAverage: _.meanBy(
              transactionRecordDataByDate[date],
              (transactionRecord) => Number(transactionRecord.price_per_share)
            ),
          }
        : {};
      return {
        time: date,
        percent: companyDataPoint?.percent,
        absolute: companyDataPoint?.absolute,
        ...Object.assign({}, ...comparisonDataPoints),
        ...Object.assign({}, ...indicatorDataPoints),
        ...transactionRecordDataPoint,
      };
    });
  }, [
    companyData,
    mode,
    superchartComparisonData,
    superchartIndicatorData,
    transactionRecordsData,
  ]);

  const lastPeriodDate = useMemo(() => {
    if (!chartData || chartData.length === 0) {
      return null;
    }
    return chartData[chartData.length - 1].time;
  }, [chartData]);

  const displayedHoverDate = useMemo(() => {
    if (hoveredDate) {
      return hoveredDate;
    }
    return lastPeriodDate;
  }, [hoveredDate, lastPeriodDate]);

  const hoveredChartElement = useMemo(() => {
    // Craftily, we'll store the actual rendering of the hovered line in memory so we can render
    // it last in the DOM, and make it appear above the greyed-out lines. We'll use the uniqueness
    // of the legend item IDs, and the fact that all data charted conforms to the same shape to
    // achieve this.
    if (hoveredLegendItem === null) {
      return null;
    }

    if (hoveredLegendItem.isVolume) {
      return (
        <>
          <Bar
            {...SHARED_BAR_PROPS}
            key={`${hoveredLegendItem.id}_bid`}
            dataKey={`${hoveredLegendItem.id}_bid`}
            fill={DataverseColors.green}
          />
          <Bar
            {...SHARED_BAR_PROPS}
            style={{ transform: "translateY(-2px)" }}
            key={`${hoveredLegendItem.id}_offer`}
            dataKey={`${hoveredLegendItem.id}_offer`}
            fill={DataverseColors.red}
          />
        </>
      );
    }
    return (
      <Line
        {...getLineProps(hoveredLegendItem, displayedHoverDate)}
        dataKey={hoveredLegendItem.dataKey || hoveredLegendItem.id}
      />
    );
  }, [hoveredLegendItem, displayedHoverDate]);

  if (!company || !companyData || !companyData.data?.length > 0) {
    return (
      <StyledLineGraphLayout>
        <StyledGraphControlRow>
          <div>
            <ActionButton
              id="add-comparison-button"
              disabled={true}
              onClick={toggleAddComparisonDropdown}
              ref={addComparisonRef}
              icon={PlusIcon}
            >
              Comparisons
            </ActionButton>
            <AddIndicatorDropdown disabled={true} />
          </div>
          <OptionSelectList
            title={null}
            defaultOption={selectedRange}
            onOptionChange={onRangeOptionChange}
            options={[
              { label: THREE_MONTHS_KEY },
              { label: SIX_MONTHS_KEY },
              { label: ONE_YEAR_KEY },
              { label: TWO_YEAR_KEY },
              { label: YTD_KEY },
            ]}
          />
        </StyledGraphControlRow>
        <ResponsiveContainer width="100%" height={300}>
          <LineChart width={500} height={300} data={[]}>
            <CartesianGrid
              horizontalPoints={[0, 75, 150, 225, 300]}
              vertical={false}
              stroke={ColorPalette.white05}
            />
          </LineChart>
          <StyledAbsoluteEmptyPill>
            <StyledCenteredEmptyState $margin={0}>
              {companyIsLoading ? "Loading..." : "No data available"}
            </StyledCenteredEmptyState>
          </StyledAbsoluteEmptyPill>
        </ResponsiveContainer>
      </StyledLineGraphLayout>
    );
  }

  return (
    <StyledLineGraphLayout>
      <StyledGraphControlRow>
        <div>
          <ActionButton
            id="add-comparison-button"
            active={addComparisonDropdown}
            onClick={toggleAddComparisonDropdown}
            ref={addComparisonRef}
            icon={PlusIcon}
          >
            Comparisons
          </ActionButton>
          <AddIndicatorDropdown
            availableIndicators={availableIndicators}
            addIndicator={onSelectIndicator}
            removeIndicator={(indicatorName) =>
              onRemoveIndicator("dropdown", indicatorName)
            }
            selectedIndicators={selectedIndicators}
          />
        </div>
        <OptionSelectList
          title={null}
          defaultOption={selectedRange}
          onOptionChange={onRangeOptionChange}
          disabled={!company}
          options={[
            { label: THREE_MONTHS_KEY },
            { label: SIX_MONTHS_KEY },
            { label: ONE_YEAR_KEY },
            { label: TWO_YEAR_KEY },
            { label: YTD_KEY },
          ]}
        />
      </StyledGraphControlRow>
      <StyledLegendContainer>
        {companyData && (
          <LegendItem
            isHovered={hoveredLegendItem?.id === companyData.id}
            onMouseEnter={() =>
              updateHoveredLegendItem({
                ...companyData,
                dataKey: companyDataKey,
              })
            }
            onMouseLeave={() => updateHoveredLegendItem(null)}
            chartElement={{
              ...companyData,
              name:
                mode === COMPARISON_MODE || selectedIndicators.length === 0
                  ? companyData.name
                  : "ZX Index Value - Current",
              exchange: mode === COMPARISON_MODE ? companyData.exchange : null,
            }}
            onRemove={null}
            hoveredDate={displayedHoverDate}
          />
        )}
        {(mode === INDICATOR_MODE || comparisons.length === 0) &&
          superchartIndicatorData.map((indicator) => {
            return (
              <indicator.LegendItem
                key={`legend_${indicator.id}`}
                isHovered={hoveredLegendItem?.id === indicator.id}
                onMouseEnter={() => updateHoveredLegendItem(indicator)}
                onMouseLeave={() => updateHoveredLegendItem(null)}
                onRemove={() => onRemoveIndicator("legend", indicator.id)}
                hoveredDate={displayedHoverDate}
                chartElement={indicator}
              />
            );
          })}
        {mode === COMPARISON_MODE &&
          superchartComparisonData.map((comparison) => (
            <LegendItem
              key={`legend_${comparison.id}`}
              isHovered={hoveredLegendItem?.id === comparison.id}
              onMouseEnter={() => updateHoveredLegendItem(comparison)}
              onMouseLeave={() => updateHoveredLegendItem(null)}
              onRemove={() => onRemoveComparison(comparison.id)}
              chartElement={comparison}
              hoveredDate={displayedHoverDate}
            />
          ))}
      </StyledLegendContainer>
      <ResponsiveContainer width="100%" height={300}>
        <ComposedChart
          width={500}
          height={300}
          data={chartData}
          margin={{ left: 20, right: 8, top: 8 }}
        >
          <CartesianGrid vertical={false} stroke="rgba(255, 255, 255, 0.05)" />
          <XAxis
            interval={0}
            fontSize={AXIS_LABEL_FONT_SIZE}
            dy={8}
            tickFormatter={(value) => DateTime.fromISO(value).toFormat("MMM")}
            ticks={xAxisTicks}
            dataKey="time"
          />
          <YAxis
            width={0}
            yAxisId="volumeYAxis"
            domain={[0, (dataMax) => dataMax * 3]}
          />
          <YAxis
            domain={["dataMin", "dataMax"]}
            orientation="right"
            fontSize={AXIS_LABEL_FONT_SIZE}
            tickFormatter={(value) =>
              mode === INDICATOR_MODE || comparisons.length === 0
                ? shortMoneyFormat(value)
                : percentFormat(value)
            }
          />
          <Tooltip
            allowEscapeViewBox={{ x: true }}
            wrapperStyle={GRAPH_TOOLTIP_STYLES}
            cursor={{ stroke: ColorPalette.white50, strokeWidth: 1 }}
            content={
              <CompanyLineGraphTooltip
                setHoveredDate={setHoveredDate}
                companyName={company?.name}
                comparisonCount={comparisons.length}
                indicatorCount={superchartIndicatorData.length}
              />
            }
          />
          {mode === COMPARISON_MODE &&
            superchartComparisonData.map((comparison) =>
              hoveredLegendItem?.id === comparison.id ? null : (
                <Line
                  {...getLineProps(comparison, displayedHoverDate)}
                  key={comparison.id}
                  stroke={
                    hoveredLegendItem === null
                      ? comparison.color
                      : YukaColorPalette.surface3
                  }
                />
              )
            )}
          {(mode === INDICATOR_MODE || comparisons.length === 0) &&
            superchartIndicatorData.map((indicator) => {
              if (hoveredLegendItem?.id === indicator.id) {
                // We can return null for this indicator b/c it'll be rendered
                // by `hoveredChartElement`.
                return null;
              }
              if (indicator.isVolume) {
                return (
                  <>
                    <Bar
                      {...SHARED_BAR_PROPS}
                      activeBar={ActiveVolumeBar(
                        `${indicator.id}_bid`,
                        DataverseColors.green,
                        0
                      )}
                      key={`${indicator.id}_bid`}
                      dataKey={`${indicator.id}_bid`}
                      fill={applyOpacityToHex(DataverseColors.green, 0.5)}
                    />
                    <Bar
                      {...SHARED_BAR_PROPS}
                      activeBar={ActiveVolumeBar(
                        `${indicator.id}_offer`,
                        DataverseColors.red,
                        1
                      )}
                      style={{ transform: "translateY(-2px)" }}
                      key={`${indicator.id}_offer`}
                      dataKey={`${indicator.id}_offer`}
                      fill={applyOpacityToHex(DataverseColors.red, 0.5)}
                    />
                  </>
                );
              }
              return (
                <Line
                  {...getLineProps(indicator, displayedHoverDate)}
                  key={indicator.id}
                  stroke={
                    hoveredLegendItem === null
                      ? indicator.color
                      : YukaColorPalette.surface3
                  }
                />
              );
            })}
          <Line
            {...getLineProps(companyData, displayedHoverDate)}
            dataKey={companyDataKey}
            stroke={
              hoveredLegendItem === null
                ? companyData.color
                : YukaColorPalette.surface3
            }
          />
          <Scatter
            stroke="yellow"
            fill={DataverseColors.yellow}
            valueKey="time"
            dataKey="transactionRecordAverage"
            shape="triangle"
            isAnimationActive={false}
            onClick={() => {
              onClickClosedTransaction({ animate: false });
            }}
            style={{ cursor: "pointer" }}
            activeShape={<Symbols type="triangle" opacity={1} />}
            opacity={0.8}
          />
          {hoveredChartElement}
        </ComposedChart>
      </ResponsiveContainer>
      <PageSection>{addComparisonDropdown}</PageSection>
    </StyledLineGraphLayout>
  );
};

CompanyProfileLineGraph.propTypes = {
  onClickClosedTransaction: PropTypes.func.isRequired,
};

export default CompanyProfileLineGraph;
