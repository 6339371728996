import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  Table,
  NumberCell,
  PercentageCell,
  ColorPalette,
  Fonts,
  YukaThemeProvider,
  YukaColorPalette,
  BookmarkIcon,
  BookmarkFilledIcon,
  IconButton,
} from "yuka";

import CompanyCellRenderer from "./CompanyCellRenderer";
import CompanyFilters from "./CompanyFilters";
import {
  RANGE_QUERY_PARAMS,
  ROBUSTNESS_FILTER,
  WATCHLIST_BOOLEAN_MAP,
  ZX_INDEX_VALUE_CHANGE_FILTER,
  LOADING_TEXT,
} from "./constants";

import { API_ENDPOINTS } from "../api/constants";
import useDelete from "../api/useDelete";
import useFetch from "../api/useFetch";
import useFetches from "../api/useFetches";
import useInfiniteFetch from "../api/useInfiniteFetch";
import useWrite from "../api/useWrite";
import { PAGE_WIDTH } from "../constants";
import { DataverseColors } from "../hdYuka/constants";
import { ROUTING_PATH } from "../routes/constants";
import {
  ACTIONS,
  COMPANY_PROFILE_MIXPANEL_TARGET,
  useDispatch,
} from "../routes/StateProvider";
import SiteFooter from "../SiteFooter/component";
import {
  MONEY_FORMAT_MAX_DECIMAL_PLACES,
  MONEY_FORMAT_MIN_DECIMAL_PLACES,
  WEEKLY,
  MIXPANEL_SOURCE_COMPANY_SCREENER,
} from "../utils/constants";
import {
  expandedMoneyFormat,
  percentFormat,
} from "../utils/displayFormatUtils";
import LoadingSpinner from "../utils/LoadingSpinner";
import MixpanelEvents from "../utils/mixpanel/MixpanelEvents";
import { StyledError } from "../utils/StyledComponents";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useWalkthrough, Walkthrough } from "../Walkthrough";

const StyledCompanyListContainer = styled.div`
  ${PAGE_WIDTH}
  padding-top: 48px;
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  gap: 24px;
  flex-direction: column;
  align-self: center;
`;

const EmptyInfoContainer = styled.div`
  padding: 0 24px;
`;

const RANGE_QUERY_PARAM_KEYS = [
  ROBUSTNESS_FILTER,
  ZX_INDEX_VALUE_CHANGE_FILTER,
  // PREMIUM_TO_LAST_ROUND_FILTER,
];

const CompanyListHeader = styled(Fonts.Headline3theme80)`
  width: fit-content;
`;

const WALKTHROUGH_STEPS = [
  {
    title: "New company screener",
    text:
      "With an updated view and more filters, you can now sift through hundreds of private " +
      "companies and discover the right fit for your investment strategy.",
    positionSelector: "#company-list-header",
  },
  {
    title: "Powerful global search",
    text: "You can quickly and easily find any ZXData private company using the global search.",
    positionSelector: "#global-search-bar",
  },
  {
    title: "Brand new portfolio feature",
    text: "You can now manage your private market investments within ZXData.",
    positionSelector: "#portfolios-global-nav-link",
  },
];

const DEFAULT_FILTERS = {
  watchlist: null,
  [ROBUSTNESS_FILTER]: null,
  [ZX_INDEX_VALUE_CHANGE_FILTER]: null,
  // [PREMIUM_TO_LAST_ROUND_FILTER]: null,
};

const EmptyTablePlaceholderContainer = styled.div`
  padding: 224px 0;
`;

const CompanyList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  useDocumentTitle("ZXData: Company List");
  const { walkthroughShowing, completeWalkthrough } = useWalkthrough(
    "walkthrough_company_list"
  );

  const [filters, setFilters] = useState(DEFAULT_FILTERS);

  useEffect(() => {
    MixpanelEvents.viewCompanyList();
  }, []);

  const queryParams = {
    ordering: "-robustness,-volume",
    time_frame: WEEKLY,
    "page[size]": 30,
    // Intentionally can be undefined to include both watchlisted and non-watchlisted.
    watchlist: WATCHLIST_BOOLEAN_MAP[filters.watchlist],
    ...RANGE_QUERY_PARAM_KEYS.reduce(
      (acc, key) =>
        filters[key]
          ? {
              ...acc,
              [`${key}_min`]: RANGE_QUERY_PARAMS[filters[key]].min,
              [`${key}_max`]: RANGE_QUERY_PARAMS[filters[key]].max,
            }
          : acc,
      {}
    ),
  };

  const queryInvalidator = [
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    queryParams,
    "paginated",
  ];

  const companyQueryInfo = useInfiniteFetch(
    API_ENDPOINTS.COMPANY_LATEST_ORDER_FLOW(),
    queryParams
  );

  const watchlistQueryInfo = useFetch(API_ENDPOINTS.WATCHLIST_COMPANIES());
  const watchlistDelete = useDelete(API_ENDPOINTS.WATCHLIST_COMPANIES(), {
    silent: true,
  });
  const watchlistUpdate = useWrite(API_ENDPOINTS.WATCHLIST_COMPANIES(), false, {
    silent: true,
  });

  // map for quick lookup on watchlisted companies
  const watchlistMap = useMemo(() => {
    if (watchlistQueryInfo.isSuccess) {
      return watchlistQueryInfo.cleanedData.reduce((obj, watchlistItem) => {
        obj[watchlistItem.company] = watchlistItem;
        return obj;
      }, {});
    }
    return {};
  }, [watchlistQueryInfo.isSuccess, watchlistQueryInfo.cleanedData]);

  const companies = useMemo(() => {
    if (companyQueryInfo.isSuccess) {
      return companyQueryInfo.cleanedData.data;
    }
    return [];
  }, [companyQueryInfo.isSuccess, companyQueryInfo.cleanedData]);

  const hdFundingRoundsQuery = useFetches(
    companies?.map((company) => ({
      url: API_ENDPOINTS.HD_FUNDING_ROUNDS(),
      queryParams: {
        company: company.apiId,
        "page[size]": 1,
      },
    }))
  );

  // We'll determine the funding round related to each company based on ordering.
  const fundingRounds = useMemo(() => {
    if (hdFundingRoundsQuery.isAnySuccess) {
      return hdFundingRoundsQuery.cleanedData.map(
        (queryResult) => queryResult?.data?.[0]
      );
    }
    return [];
  }, [hdFundingRoundsQuery.isAnySuccess, hdFundingRoundsQuery.cleanedData]);

  const fundingRoundsMap = useMemo(() => {
    let result = {};
    for (let i = 0; i < companies.length; i++) {
      if (!fundingRounds[i] && hdFundingRoundsQuery.queryInfo[i].isLoading) {
        // Check to see if it's loading
        result[companies[i].apiId] = LOADING_TEXT;
      } else {
        result[companies[i].apiId] = fundingRounds[i];
      }
    }
    return result;
  }, [companies, fundingRounds, hdFundingRoundsQuery.queryInfo]);

  const transactionRecordsQuery = useFetches(
    companies?.map((company) => ({
      url: API_ENDPOINTS.TRANSACTION_RECORDS(),
      queryParams: {
        company: company.apiId,
      },
    }))
  );

  const transactionRecordSort = (a, b) => {
    const dateA = a.closing_date || a.noticed_date;
    const dateB = b.closing_date || b.noticed_date;
    if (!dateA || dateA < dateB) {
      return 1;
    }
    if (!dateB || dateA > dateB) {
      return -1;
    }
    return 0;
  };

  // We'll determine the transaction records related to each company based on ordering.
  const transactionRecords = useMemo(() => {
    if (transactionRecordsQuery.isAnySuccess) {
      return transactionRecordsQuery.cleanedData.map((queryResult) => {
        const sortedQueryResult = queryResult?.data?.sort(
          transactionRecordSort
        );
        return sortedQueryResult?.[0];
      });
    }
    return [];
  }, [
    transactionRecordsQuery.isAnySuccess,
    transactionRecordsQuery.cleanedData,
  ]);

  const transactionRecordsMap = useMemo(() => {
    let result = {};
    for (let i = 0; i < companies.length; i++) {
      if (
        !transactionRecords[i] &&
        transactionRecordsQuery.queryInfo[i].isLoading
      ) {
        // Check to see if it's loading
        result[companies[i].apiId] = LOADING_TEXT;
      } else {
        result[companies[i].apiId] = transactionRecords[i];
      }
    }
    return result;
  }, [companies, transactionRecords, transactionRecordsQuery.queryInfo]);

  /*
   * Used to wrap the `setFilters` function to use the child filter buttons onChange interface.
   */
  const makeOnChangeFunction = useCallback(
    (fieldName) => {
      return (newValue) => {
        MixpanelEvents.filterCompanyList({ ...filters, [fieldName]: newValue });
        setFilters({
          ...filters,
          [fieldName]: newValue,
        });
      };
    },
    [filters]
  );

  const watchlistCompany = (companyId, companyName) => {
    watchlistUpdate.mutate(
      { company: companyId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: queryInvalidator });
        },
      }
    );
    MixpanelEvents.addCompanyToWatchlist(
      MIXPANEL_SOURCE_COMPANY_SCREENER,
      companyName
    );
  };

  const removeWatchlistedCompany = (companyId, companyName) => {
    watchlistDelete.mutate(
      { id: companyId },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: queryInvalidator });
        },
      }
    );
    MixpanelEvents.removeCompanyFromWatchlist(
      MIXPANEL_SOURCE_COMPANY_SCREENER,
      companyName
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        id: "watchlist",
        accessor: (company) => company,
        cellRenderer: ({
          value: { apiId, name },
          watchlistMap,
          watchlistDeleteLoading,
          watchlistDeleteCompanyId,
          watchlistUpdateLoading,
          watchlistUpdateCompanyId,
          removeWatchlistedCompany,
          watchlistCompany,
        }) => {
          const watchlisted = apiId in watchlistMap;
          if (watchlisted) {
            if (watchlistDeleteLoading && watchlistDeleteCompanyId === apiId) {
              return (
                <div>
                  <LoadingSpinner showContainer={false} absolute={false} />
                </div>
              );
            }
            return (
              <IconButton
                icon={BookmarkFilledIcon}
                iconColor={DataverseColors.branding500}
                onClick={(e) => {
                  removeWatchlistedCompany(apiId, name);
                  e.stopPropagation();
                }}
              />
            );
          }
          if (watchlistUpdateLoading && watchlistUpdateCompanyId === apiId) {
            return (
              <div>
                <LoadingSpinner showContainer={false} absolute={false} />
              </div>
            );
          }
          return (
            <IconButton
              icon={BookmarkIcon}
              onClick={(e) => {
                watchlistCompany(apiId, name);
                e.stopPropagation();
              }}
            />
          );
        },
        renderOverflow: true,
        align: "center",
        width: 50,
        useFixedWidth: true,
        header: "",
        sticky: true,
      },
      {
        id: "name",
        accessor: (company) => ({
          name: company.name,
          avatar: company.main_picture,
        }),
        header: "Company",
        width: 250,
        sticky: true,
        cellRenderer: CompanyCellRenderer,
      },
      {
        id: "zx_index_value",
        accessor: "zx_index_value",
        header: "ZX Index Value",
        width: 130,
        cellRenderer: NumberCell,
        formatter: (value) =>
          value ? (
            expandedMoneyFormat(
              value,
              MONEY_FORMAT_MIN_DECIMAL_PLACES,
              MONEY_FORMAT_MAX_DECIMAL_PLACES
            )
          ) : (
            <Fonts.Body1theme30>--</Fonts.Body1theme30>
          ),
      },
      {
        id: "weekChange",
        accessor: "zx_index_value_percent_change",
        header: "Week Change",
        width: 120,
        cellRenderer: PercentageCell,
        isUnitInterval: true,
      },
      {
        id: "lastTrade",
        header: "Last Trade",
        accessor: (company) => ({ company, transactionRecordsMap }),
        width: 100,
        cellRenderer: NumberCell,
        formatter: ({ company, transactionRecordsMap }) => {
          const transactionRecord = transactionRecordsMap[company.apiId];
          if (transactionRecord === LOADING_TEXT) {
            return LOADING_TEXT;
          }
          if (!transactionRecord || !transactionRecord.price_per_share) {
            return <Fonts.Body1theme30>--</Fonts.Body1theme30>;
          }
          return expandedMoneyFormat(
            transactionRecord.price_per_share,
            MONEY_FORMAT_MIN_DECIMAL_PLACES,
            MONEY_FORMAT_MAX_DECIMAL_PLACES
          );
        },
      },
      {
        id: "lastRoundPrice",
        accessor: (company) => ({ company, fundingRoundsMap }),
        header: "LR Price",
        width: 100,
        cellRenderer: NumberCell,
        formatter: ({ company, fundingRoundsMap }) => {
          const fundingRound = fundingRoundsMap[company.apiId];
          if (fundingRound === LOADING_TEXT) {
            return LOADING_TEXT;
          }
          if (!fundingRound || !fundingRound.price_per_share) {
            return <Fonts.Body1theme30>--</Fonts.Body1theme30>;
          }
          return expandedMoneyFormat(
            fundingRound.price_per_share,
            MONEY_FORMAT_MIN_DECIMAL_PLACES,
            MONEY_FORMAT_MAX_DECIMAL_PLACES
          );
        },
      },
      {
        id: "volume",
        accessor: "total_volume",
        header: "$ Volume (M)",
        width: 120,
        cellRenderer: NumberCell,
        formatter: (value) => expandedMoneyFormat(value, 0, 0),
      },
      {
        id: "robustnessScore",
        accessor: "robustness",
        header: "Robustness Score",
        width: 160,
        cellRenderer: NumberCell,
        formatter: (value) =>
          (
            <>
              {value}
              <Fonts.Body1theme50>/10</Fonts.Body1theme50>
            </>
          ) || <Fonts.Body1theme30>--</Fonts.Body1theme30>,
      },
      {
        id: "bid_ask_ratio",
        accessor: "bid_ask_ratio",
        header: "Bid/Ask Ratio",
        width: 120,
        cellRenderer: NumberCell,
        formatter: (value) => percentFormat(value * 100, 0),
      },
    ],
    [fundingRoundsMap, transactionRecordsMap]
  );

  if (companyQueryInfo.isError) {
    return (
      <StyledCompanyListContainer>
        <Fonts.Headline3theme80>Company List</Fonts.Headline3theme80>
        <CompanyFilters
          resetFilters={() => setFilters(DEFAULT_FILTERS)}
          filters={filters}
          makeOnChangeFunction={makeOnChangeFunction}
        />
        <EmptyInfoContainer>
          <StyledError>An error has occurred.</StyledError>
        </EmptyInfoContainer>
      </StyledCompanyListContainer>
    );
  }

  const onRowClick = ({ row: { index } }) => {
    const companyId = companies[index].apiId;
    MixpanelEvents.clickCompanyListItem(companies[index].name);
    dispatch({
      type: ACTIONS.updateMixpanelEventSource,
      source: "company screener",
      target: COMPANY_PROFILE_MIXPANEL_TARGET,
    });
    navigate(ROUTING_PATH.COMPANY(companyId));
  };

  return (
    <YukaThemeProvider
      theme={{
        tableStyles: {
          colors: {
            header: YukaColorPalette.surface0,
            row: YukaColorPalette.surface0,
            rowHover: ColorPalette.surface1,
          },
          header: {
            height: 42,
            borderTop: `1px dashed ${ColorPalette.white15}`,
            borderBottom: `1px dashed ${ColorPalette.white15}`,
          },
          cells: {
            height: 56,
            borderBottom: `1px dashed ${ColorPalette.white15}`,
          },
        },
      }}
    >
      <StyledCompanyListContainer>
        <CompanyListHeader id="company-list-header">
          Company Screener
        </CompanyListHeader>
        {walkthroughShowing && (
          <Walkthrough
            name="company screener"
            steps={WALKTHROUGH_STEPS}
            onFinish={completeWalkthrough}
          />
        )}
        <CompanyFilters
          resetFilters={() => setFilters(DEFAULT_FILTERS)}
          filters={filters}
          makeOnChangeFunction={makeOnChangeFunction}
        />
        <Table
          watchlistMap={watchlistMap}
          watchlistDeleteLoading={watchlistDelete.isLoading}
          watchlistDeleteCompanyId={watchlistDelete.variables?.id}
          watchlistUpdateLoading={watchlistUpdate.isLoading}
          watchlistUpdateCompanyId={watchlistUpdate.variables?.company}
          removeWatchlistedCompany={removeWatchlistedCompany}
          watchlistCompany={watchlistCompany}
          data={companies}
          fundingRoundsMap={fundingRoundsMap}
          onRowClick={onRowClick}
          paginationFunc={
            companyQueryInfo.hasNextPage && !companyQueryInfo.isFetchingNextPage
              ? companyQueryInfo.fetchNextPage
              : null
          }
          isLoading={companyQueryInfo.isLoading}
          isPaginationLoading={companyQueryInfo.isFetchingNextPage}
          columns={tableColumns}
          emptyTablePlaceholder={
            <EmptyTablePlaceholderContainer>
              <Fonts.Headline1theme30>
                No companies match your screen. Please update or remove filters
                and try again.
              </Fonts.Headline1theme30>
            </EmptyTablePlaceholderContainer>
          }
        />
      </StyledCompanyListContainer>
      <SiteFooter />
    </YukaThemeProvider>
  );
};

export default CompanyList;
