import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Fonts } from "yuka";

import convertTotalValueBucketed from "../../utils/convertTotalValueBucketed";
import { expandedMoneyFormat } from "../../utils/displayFormatUtils";
import MixpanelEvents from "../../utils/mixpanel/MixpanelEvents";

const debouncedMixpanel = _.debounce(
  MixpanelEvents.hoverCompanyProfileLineChart,
  500,
  { leading: false, trailing: true }
);

const CompanyLineGraphTooltip = ({
  setHoveredDate,
  payload,
  companyName,
  comparisonCount,
  indicatorCount,
}) => {
  // Props are injected by recharts.
  useEffect(() => {
    if (payload[0]?.payload?.time) {
      debouncedMixpanel(
        companyName,
        payload[0]?.payload?.time,
        comparisonCount,
        indicatorCount
      );
    }
    setHoveredDate(payload[0]?.payload?.time);
  }, [companyName, comparisonCount, indicatorCount, setHoveredDate, payload]);
  if (!payload[0]?.payload?.time) {
    return null;
  }

  const formattedDate = DateTime.fromISO(payload[0]?.payload?.time).toFormat(
    "dd MMM yy"
  );
  const [day, month, year] = formattedDate.split(" ");
  // Inject the apostrophe to the year.
  const { transactionRecords } = payload[0]?.payload;

  if (!transactionRecords) {
    return `${day} ${month} '${year}`;
  }
  return (
    <>
      <span>
        {day} {month} '{year}
      </span>
      {transactionRecords.map((record) => (
        <div key={record.id}>
          <div>
            <Fonts.Headline3theme80>
              {expandedMoneyFormat(record.price_per_share, 2, 2)}
            </Fonts.Headline3theme80>
          </div>
          Trade closed on{" "}
          {DateTime.fromISO(record.closing_date).toFormat("M/d/y")} at{" "}
          {convertTotalValueBucketed(record.total_value, 0)}
        </div>
      ))}
    </>
  );
};

CompanyLineGraphTooltip.propTypes = {
  setHoveredDate: PropTypes.func,
  payload: PropTypes.array,
  companyName: PropTypes.string,
  comparisonCount: PropTypes.number,
  indicatorCount: PropTypes.number,
};

export default CompanyLineGraphTooltip;
