import _ from "lodash";

import { TOTAL_VALUE_BUCKETS } from "./constants";
import convertNumToReadable from "./convertNumToReadable";

/**
 * Convert a number into a bucketed number (e.g: 11mn => 10mn - 25mn).
 * This has some similarities to ``convert_total_value_bucketed``
 *
 * @param {number} numRaw - the number to be converted
 * @param {number} precision - optional argument to specify number of decimal places
 * @returns {string} bucket value representation of num
 */
const convertTotalValueBucketed = (numRaw, precision) => {
  const num = _.isString(numRaw) ? Number.parseFloat(numRaw) : numRaw;

  let bucketString = `>$${convertNumToReadable(
    _.last(TOTAL_VALUE_BUCKETS),
    precision
  )}`;
  // eslint-disable-next-line consistent-return
  _.forEach(TOTAL_VALUE_BUCKETS, (bucketValue, index) => {
    if (num < bucketValue) {
      bucketString = index
        ? `$${convertNumToReadable(
            TOTAL_VALUE_BUCKETS[index - 1],
            precision
          )} - ` + `$${convertNumToReadable(bucketValue, precision)}`
        : `<$${convertNumToReadable(bucketValue, precision)}`;
      return false;
    }
  });
  return bucketString;
};

export default convertTotalValueBucketed;
