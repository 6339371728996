import { DateTime } from "luxon";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  YukaThemeProvider,
  Fonts,
  Table,
  DateCell,
  Button,
  ButtonStyles,
  CurrencyCell,
  PercentageCell,
} from "yuka";

import { FundingTableStyles } from "./FundingCard/constants";

import { API_ENDPOINTS } from "../../api/constants";
import useFetch from "../../api/useFetch";
import { SurfaceZeroCard } from "../../hdYuka";
import convertTotalValueBucketed from "../../utils/convertTotalValueBucketed";

const StyledSubtitle = styled.div`
  padding-bottom: 16px;
`;

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-top: 16px;
`;

const SECURITY_TYPE_CHOICES = {
  11: "Common",
  12: "Preferred",
};

const STRUCTURE_CHOICES = {
  1: "Derivative",
  2: "Direct",
  3: "SPV",
};

const SOURCE_TYPE_RUMORED = 200;

const INITIAL_TABLE_SIZE = 5;

const EMPTY_VALUE_PLACEHOLDER = <Fonts.Body1theme50>--</Fonts.Body1theme50>;

const columns = [
  {
    id: "structure",
    header: "Structure",
    accessor: (transactionRecord) => ({
      structure: transactionRecord.structure,
      source_type: transactionRecord.source_type,
    }),
    width: 170,
    cellRenderer: ({ value }) => {
      if (!value.structure) {
        return EMPTY_VALUE_PLACEHOLDER;
      }

      // We're using "Uncomfirmed" copy since "Rumored" is non-compliant
      return (
        <>
          {STRUCTURE_CHOICES[value.structure]}
          {value.source_type == SOURCE_TYPE_RUMORED && (
            <Fonts.Body1theme50>&nbsp;Uncomfirmed</Fonts.Body1theme50>
          )}
        </>
      );
    },
  },
  {
    id: "date",
    header: "Close Date",
    accessor: (transactionRecord) =>
      transactionRecord.closing_date || transactionRecord.noticed_date,
    width: 140,
    cellRenderer: DateCell,
    // using default DateCell dateFormatter causes off-by-one-day error
    dateFormatter: (value) => DateTime.fromISO(value).toFormat("M/dd/yyyy"),
  },
  {
    id: "price",
    header: "Price",
    accessor: "price_per_share",
    width: 120,
    cellRenderer: CurrencyCell,
  },
  {
    id: "size",
    header: "Size",
    accessor: "total_value",
    width: 140,
    cellRenderer: ({ value }) => convertTotalValueBucketed(value, 0),
  },
  {
    id: "security",
    header: "Security",
    accessor: "security_type",
    width: 140,
    cellRenderer: ({ value }) =>
      value ? SECURITY_TYPE_CHOICES[value] : EMPTY_VALUE_PLACEHOLDER,
  },
  {
    id: "rofr_percent",
    header: "ROFR%",
    accessor: (transactionRecord) => {
      if (transactionRecord.rofr_value && transactionRecord.total_value) {
        return (
          100 * (transactionRecord.rofr_value / transactionRecord.total_value)
        );
      }
    },
    width: 90,
    cellRenderer: PercentageCell,
    precision: 0,
  },
];

const ClosedTransactionsCard = () => {
  const [isShowingMore, setIsShowingMore] = useState(false);

  const { id: companyId } = useParams();

  const transactionRecordSort = (a, b) => {
    const dateA = a.closing_date || a.noticed_date;
    const dateB = b.closing_date || b.noticed_date;
    if (!dateA || dateA < dateB) {
      return 1;
    }
    if (!dateB || dateA > dateB) {
      return -1;
    }
    return 0;
  };

  // large page size so that we can get everything in one page
  const transactionRecords = useFetch(API_ENDPOINTS.TRANSACTION_RECORDS(), {
    company: companyId,
    "page[size]": 100000,
  });
  const transactionRecordsData = transactionRecords?.cleanedData?.data;

  const sortedTransactionRecordsData = React.useMemo(
    () => transactionRecordsData?.sort(transactionRecordSort),
    [transactionRecordsData]
  );

  const numberOfRows = isShowingMore
    ? transactionRecordsData.length
    : INITIAL_TABLE_SIZE;

  if (transactionRecords.isLoading) {
    return (
      <SurfaceZeroCard title="Closed transactions">
        <StyledSubtitle>
          <Fonts.Caption2theme30>
            Data is collected from the ZXData Partners network
          </Fonts.Caption2theme30>
        </StyledSubtitle>
        Loading...
      </SurfaceZeroCard>
    );
  }

  if (!transactionRecordsData || transactionRecords.isError) {
    return (
      <SurfaceZeroCard title="Closed transactions">
        <Fonts.Body1theme30>An error has occurred.</Fonts.Body1theme30>
      </SurfaceZeroCard>
    );
  }

  return (
    <SurfaceZeroCard title="Closed transactions">
      <StyledSubtitle>
        <Fonts.Caption2theme30>
          Data is collected from the ZXData Partners network
        </Fonts.Caption2theme30>
      </StyledSubtitle>
      <YukaThemeProvider theme={{ tableStyles: FundingTableStyles }}>
        <Table
          data={sortedTransactionRecordsData.slice(0, numberOfRows)}
          columns={columns}
        />
        {sortedTransactionRecordsData.length > INITIAL_TABLE_SIZE && (
          <StyledButtonContainer>
            <Button
              buttonStyle={ButtonStyles.RAISED}
              onClick={() => {
                setIsShowingMore(!isShowingMore);
              }}
            >
              {isShowingMore ? "Show fewer" : "Show more"}
            </Button>
          </StyledButtonContainer>
        )}
      </YukaThemeProvider>
    </SurfaceZeroCard>
  );
};

export default ClosedTransactionsCard;
